import { images } from "../../../assets/images";

export const industriesData = [
  {
    title: "Automotive",
    icon: images.industries1,
  },
  {
    title: "Healthcare",
    icon: images.industries2,
  },
  {
    title: "Government",
    icon: images.industries3,
  },
  {
    title: "Event",
    icon: images.industries4,
  },
  {
    title: "Retails",
    icon: images.industries5,
  },
  {
    title: "Transport",
    icon: images.industries6,
  },
  {
    title: "Automotive",
    icon: images.industries1,
  },
  {
    title: "Healthcare",
    icon: images.industries2,
  },
  {
    title: "Government",
    icon: images.industries3,
  },
  {
    title: "Event",
    icon: images.industries4,
  },
  {
    title: "Retails",
    icon: images.industries5,
  },
  {
    title: "Transport",
    icon: images.industries6,
  },
];
