import React from "react";
import './style.scss';


const ContactUs = ()=>{
    return(
        <>
        <section className="contact-wrapper">
            <section className="contact-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-7">
                            <h3>Let's chat about your new project</h3>
                            <p>send us a quick email at hello@transparenthouse.com</p>
                        </div>
                        <div className="col-5 ">
                            <div className="contact-info">
                                <span>Prefer to talk?</span>
                                <p>+91-9876543210</p>
                            </div>
                            <div className="contact-info">
                                <span>Our Office address</span>
                                <p>A-28, Sector 4,<br/>
                                    Noida, Uttar Pradesh,<br/>
                                    India
                                </p>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
            <section className="contact-form">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="consult-card">
                                <div>
                                    <img src={require('../../../assets/consultimg.png')} alt="" />
                                    <h3>Free 30min consultation</h3>
                                    <p>Have a new project in mind? Schedule a 30-minute discovery call or fill out the quick form, and together we'll explore the possibilities.</p>
                                    <button className="btn-white">
                                        Schedule a call
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <form>
                                <div className="d-flex gap-2">
                                    <div>
                                        <label>Name
                                            <input type="text"  placeholder="Your Name"/>
                                        </label>                                        
                                    </div>
                                    <div>
                                        <label>Email
                                            <input type="text"  placeholder="Your Email"/>
                                        </label>                                        
                                    </div>
                                </div>
                                <div className="d-flex gap-2">
                                    <div>
                                        <label>Mobile
                                            <input type="text"  placeholder="Phone No.  "/>
                                        </label>                                        
                                    </div>
                                    <div>
                                        <label>Estimated Budget
                                            <input type="text"  placeholder="Select One"/>
                                        </label>                                        
                                    </div>
                                </div>
                                <div className="d-flex gap-2">
                                    <div>
                                        <label>Organisation
                                            <input type="text"  placeholder="XYZ"/>
                                        </label>                                        
                                    </div>
                                    <div>
                                        <label>Industry
                                            <input type="text"  placeholder="Industry"/>
                                        </label>                                        
                                    </div>
                                </div>
                                <div>
                                    <label>
                                        <textarea placeholder="Project Brief" name="" id="" cols="30" rows="5"></textarea>
                                    </label>
                                </div>
                                <input type="submit" value="Send " />

                            </form>
                        </div>
                    </div>
                </div>
            </section>
        
            
        </section>
        </>
    )
}
export default ContactUs;