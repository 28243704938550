import React from "react";
import Header from "./header/Header";
import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";

const Layouts = () => {

  return (
    <>
      <Header/>
      <Outlet />
      <Footer/>
    </>
  );
};

export default Layouts;