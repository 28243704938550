import {
  Navigate,
  useRoutes,
} from "react-router-dom";
import { ComingSoon, Home } from "../views/pages";
import Layouts from "../views/layouts/Layouts";
import AboutPage from "../views/pages/About/About";
import Career from "../views/pages/Career";
import TechnologiesPage from "../views/pages/Technologies";
import Portfoliopage from "../views/pages/Portfolio";
import ContactUs from "../views/pages/Contact-us/contact-us";
import Servicepage from "../views/pages/Service/service";

const Router = () => {

  const element = useRoutes([
    {
      path: "/",
      element: <Layouts />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <AboutPage />,
        },
        {
          path: "/career",
          element: <Career />,
        },
        {
          path:'/technology',
          element:<TechnologiesPage/>
        },
        {
          path:'/portfolio',
          element:<Portfoliopage/>
        },
        {
          path:'/contact',
          element:<ContactUs/>
        },
        {path:'/services',
        element:<Servicepage/>},

      ],
    },
    {
      path: "404",
      element: <ComingSoon />,
    },
    // {
    //   path: "/",
    //   element: <Navigate to="404" replace />,
    // },
    {
      path: "*",
      element: <Navigate to="404" replace />,
    },
  ]);

  return element;
};

export default Router;
