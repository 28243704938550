import React from "react";
import { images } from "../../../assets/images";
import PorgressBar from "../../../components/utils/PorgressBar/ProgressBar";
import "./ComingSoon.scss";
import { Link } from "react-router-dom";

export default function ComingSoon() {
  return (
    <div className="coming-soon">
      <div className="coming-soon-container">
        <div className="container">
          <div className="row logo">
            <Link to="/">
              {" "}
              <img src={images.logoWhite} alt="" />
            </Link>
          </div>
          <div className="row content">
            <h5>Our website will come very soon</h5>
            <p>
              Stay tuned for the big reveal. In the meantime, you can connect
              with us through our social media channels to get sneak peeks,
              updates, and exclusive offers.
            </p>
          </div>
          <div className="row">
            <PorgressBar />
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row copyright-text">
            <div className="col-3">ALL RIGHT RESERVED. Copyright@2023.</div>
            <div className="col-4">Privacy Policy</div>
            <div className="col-5 text-center">
              Designed By <Link to="/">Techxerro</Link>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
