import React from "react";
import './style.scss';

const Portfoliopage = () =>{
    return(
            <>
                <section className="portfolio-head">
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-9">
                                <h3>UI/UX Design Portfolio</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Semper ac aliquet facilisis aliquam risus placerat quis eleifend. Est ornare elementum malesuada aliquam id in id a nunc.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="portfolio port1">
                    <div className="container">
                        <div className="row">
                            <div className="col-6 d-flex align-items-center">
                                <div>                                    
                                    <h4>Ore System</h4>
                                    <p>We use the most innovative solutions to provide customized development solutions. Your wish is a command for us.</p>
                                    <div className="d-flex gap-3">
                                        <div className="icons">
                                            <img src={require('../../../assets/techs/feicon3.png')} alt="" />
                                        </div>
                                        <div className="icons">
                                            <img src={require('../../../assets/techs/mobicon1.png')} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <img src={require('../../../assets/portfolio1.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="portfolio port2">
                    <div className="container">
                        <div className="row">
                            <div className="col-6 d-flex align-items-center">
                                <div>                                    
                                    <h4>Flash Muscles</h4>
                                    <p>We use the most innovative solutions to provide customized development solutions. Your wish is a command for us.</p>
                                    <div className="d-flex gap-3">
                                        <div className="icons">
                                            <img src={require('../../../assets/techs/feicon3.png')} alt="" />
                                        </div>
                                        <div className="icons">
                                            <img src={require('../../../assets/techs/mobicon1.png')} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <img src={require('../../../assets/portfolio2.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="portfolio port3">
                    <div className="container">
                        <div className="row">
                            <div className="col-6 d-flex align-items-center">
                                <div>                                    
                                    <h4>Vinci Construction</h4>
                                    <p>We use the most innovative solutions to provide customized development solutions. Your wish is a command for us.</p>
                                    <div className="d-flex gap-3">
                                        <div className="icons">
                                            <img src={require('../../../assets/techs/feicon3.png')} alt="" />
                                        </div>
                                        <div className="icons">
                                            <img src={require('../../../assets/techs/mobicon1.png')} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <img src={require('../../../assets/portfolio3.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="portfolio port4">
                    <div className="container">
                        <div className="row">
                            <div className="col-6 d-flex align-items-center">
                                <div>                                    
                                    <h4>Ovate Textile</h4>
                                    <p>We use the most innovative solutions to provide customized development solutions. Your wish is a command for us.</p>
                                    <div className="d-flex gap-3">
                                        <div className="icons">
                                            <img src={require('../../../assets/techs/feicon3.png')} alt="" />
                                        </div>
                                        <div className="icons">
                                            <img src={require('../../../assets/techs/mobicon1.png')} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <img src={require('../../../assets/portfolio4.png')} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="cta-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h3>Are you thinking to build your own website</h3>
                                <br/>
                                <button className="btn"> Let's Talk </button>
                            </div>
                        </div>
                    </div>
                </section>
            </>
    )
}
export default Portfoliopage;

