import { images } from "../../../assets/images";

export const ClientsData = [
  {
    title: "Customerly Neque pulvinar in nulla et. Velit lectus tortor eros dignissim.",
    des: "Its marketing platform and product suite. The project spanned 12 weeks and produced a brand new User Flow, a complete UI Kit, and a marketing platform with Onboarding ready for launch.",
    img: images.client2,
    imgSm:images.client2sm
  },
  {
    title: "Customerly Neque pulvinar in nulla et. Velit lectus tortor eros dignissim.",
    des: "Its marketing platform and product suite. The project spanned 12 weeks and produced a brand new User Flow, a complete UI Kit, and a marketing platform with Onboarding ready for launch.",
    img: images.client2,
    imgSm:images.client2sm
  },
  {
    title: "Customerly Neque pulvinar in nulla et. Velit lectus tortor eros dignissim.",
    des: "Its marketing platform and product suite. The project spanned 12 weeks and produced a brand new User Flow, a complete UI Kit, and a marketing platform with Onboarding ready for launch.",
    img: images.client2,
    imgSm:images.client2sm
  },
  {
    title: "Customerly Neque pulvinar in nulla et. Velit lectus tortor eros dignissim.",
    des: "Its marketing platform and product suite. The project spanned 12 weeks and produced a brand new User Flow, a complete UI Kit, and a marketing platform with Onboarding ready for launch.",
    img: images.client2,
    imgSm:images.client2sm
  },
  {
    title: "Customerly Neque pulvinar in nulla et. Velit lectus tortor eros dignissim.",
    des: "Its marketing platform and product suite. The project spanned 12 weeks and produced a brand new User Flow, a complete UI Kit, and a marketing platform with Onboarding ready for launch.",
    img: images.client2,
    imgSm:images.client2sm
  },
  {
    title: "Customerly Neque pulvinar in nulla et. Velit lectus tortor eros dignissim.",
    des: "Its marketing platform and product suite. The project spanned 12 weeks and produced a brand new User Flow, a complete UI Kit, and a marketing platform with Onboarding ready for launch.",
    img: images.client2,
    imgSm:images.client2sm
  },
];
