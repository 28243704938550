import { images } from "../../../assets/images";

export const bannerData =[
    {
        id:1,
        titleText:"A Software Development Company",
        titleH1:"A Software",
        titleH2:"Development Company",
        titleImg:images.computer,
        bannerImg:images.BannerImg1,
        bannerPoint:[
            {
                Img:images.digitalMargeting,
                Heading:"Digital Marketing",
                Content:"Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem.",
            },
            {
                Img:images.Desiging,
                Heading:"Designing",
                Content:"Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem.",
            },
            {
                Img:images.computer,
                Heading:"Web & App Development",
                Content:"Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem.",
            }
        ]
    },
    {
        id:2,
        titleText:"A Software Marketing Company",
        titleH1:"We always",
        titleH2:"need a better content",
        titleImg:images.computer,
        bannerImg:images.BannerImg2,
        bannerPoint:[
            {
                Img:images.digitalMargeting,
                Heading:"Content creation",
                Content:"Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem.",
            },
            {
                Img:images.Desiging,
                Heading:"Social media content",
                Content:"Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem.",
            },
            {
                Img:images.computer,
                Heading:"Website content",
                Content:"Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem.",
            }
        ]
    },
    {
        id:3,
        titleText:"A Software Marketing Company",
        titleH1:"We need to",
        titleH2:"talk about personal branding",
        titleImg:images.computer,
        bannerImg:images.BannerImg3,
        bannerPoint:[
            {
                Img:images.digitalMargeting,
                Heading:"Social media management",
                Content:"Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem.",
            },
            {
                Img:images.Desiging,
                Heading:"Personal branding",
                Content:"Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem.",
            },
            {
                Img:images.computer,
                Heading:"PR & Talent management",
                Content:"Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem.",
            }
        ]
    }
]