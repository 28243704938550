import React, { useEffect, useRef, useState } from "react";
import { Progress } from "flowbite-react";
import { useOnScreen } from "../useOnScreen";
import "./ProgressBar.scss";

export default function PorgressBar() {
  const customTheme = {
    base: "progressBar",
    color: {
      dark: "bg-red-500 hover:bg-red-600",
    },
    bar: "progressBar-bar",
  };

  const progressEl = useRef(null);
  const isVisible = useOnScreen(progressEl);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    let timer;
    if (isVisible) {
      let progressEl = document.getElementsByClassName("progressBar-bar");
      let prog = 0;
      if (progressEl && progressEl[0] && progressEl[0].classList.contains("blink")) {
        progressEl[0].classList.remove("blink");
      }
      timer = setInterval(() => {
        if (prog === 80) {
          // prog = 0;
          clearInterval(timer);
          if (progressEl && progressEl[0]) {
            progressEl[0].classList.add("blink");
          }
        }
        prog += 1;
        setProgress(prog);
      }, 500);
    } else if (timer) {
      clearInterval(timer);
    }
  }, [isVisible]);
  return (
    <div ref={progressEl} className="progress-bar-container">
      <Progress
        labelProgress
        progress={progress}
        size="lg"
        theme={customTheme}
      />
    </div>
  );
}
