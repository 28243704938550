import React from "react";
import './style.scss';
import { images } from "../../../assets/images";

const AboutPage = () =>{
    return(
            <>
            <section className="about-banner-m">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-12" style={{height:'350px', display:'flex', alignItems:'center'}} >
                            <h3>Who we are</h3>                          
                        </div>
                        <div className="col-sm-8 col-12" style={{height:'350px', display:'flex', alignItems:'end'}}>      
                            <div>               
                            <h2>Unlock Your Digital<br/> Potential</h2>
                            <p>TechXerro: Your catalyst for digital dominance. Elevate your brand's online identity with our seamless blend of creativity and data-driven strategies.</p> 
                            </div>                          
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-story-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-6">
                            <img src={images.ourstoryimg} alt="" />
                        </div>
                        <div className="col-6">
                            <h4>Some Thing Know About Us</h4>
                            <h6>A Multidisciplinary Powerhouse</h6>

                            <p> At Techxerro, we understand that success in the online world hinges on more than just flashy websites and clever marketing campaigns. It's about crafting a compelling narrative that resonates with your audience, combined with a robust technical foundation that delivers a seamless user experience. That's why we approach every client engagement with a unique blend of creativity, technical expertise, and strategic ingenuity. We're a diverse team of storytellers, designers, developers, and marketing mavens, united by a shared passion for pushing boundaries and delivering results that leave a lasting impact.</p>
                            
                            <h6>Tailored Solutions, Collaborative Approach</h6>
                            <p>Our process begins with a deep dive into your business, your goals, and your audience. We take the time to understand the challenges you face, the opportunities you seek to exploit, and the unique value proposition that sets you apart. Armed with these insights, we craft tailored strategies that speak directly to your target market, leveraging the latest tools and technologies. But what truly sets us apart is our commitment to collaboration. We work hand-in-hand with our clients, ensuring our solutions not only meet but exceed expectations through open communication and a shared vision for success.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-value-section">
                <div className="container">
                    <div className="row pb-5">
                        <div className="col-3">
                            <h4><span>Our</span> Vision</h4>
                        </div>
                        <div className="col-9">
                            <p>In today's hyper-competitive marketplace, we at TechXerro share a profound vision - to empower every business, regardless of size or budget, to transform into a formidable brand. We believe that every enterprise deserves an equal opportunity to thrive, and that's precisely what fuels our relentless pursuit.</p>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5 ">
                            <h4>Democratizing Brand Excellence</h4>
                            <p>For too long, small businesses have been overshadowed by big players with massive budgets. Our vision is to level that playing field, making top-notch branding, marketing, and consulting affordable for companies of any size. We envision success determined by passion and execution, not finances.</p>
                            <p className="pb-4">At TechXerro, we know building a brand goes beyond just visuals. It's about crafting a compelling story, forging emotional connections, and delivering exceptional experiences. Our vision is to guide you through that transformative journey as your steadfast partner, with our comprehensive service suite.</p>    
                        
                            <h4>One-Stop Brand Empowerment</h4>
                            <p>Whether reinvigorating your online presence, amplifying marketing, or navigating business consulting - we're your one-stop brand empowerment hub. Our vision is to relieve that burden, allowing you to focus on your core strengths while we elevate your brand.</p>
                            <p>At our core is a commitment to deeply understanding your unique challenges and tailoring solutions aligned with your goals. We envision a world where businesses feel empowered, supported and confident to soar above the competition, leaving a lasting impression on customers.</p>
                        </div>
                        <div className="col-7">
                            <img src={require('../../../assets/visionimg1.png')} alt="" id="vission1"/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="aboutlogo">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <img src={require('../../../assets/aboutlogo.png')} alt="" />
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <div>
                                <h2>What Our Logo Says</h2>
                                <p>At the core of all technology lies the fundamental language of binary - a world shaped by 0s and 1s. Our logo ingeniously pays homage to this digital foundation by deriving the stylized "T" from the binary digit "1". The "X" with the superscript "0" represents the mathematical concept that any number raised to the power of zero equals 1.</p>
                                <p>By seamlessly blending the digital "1" from binary equations with the mathematical principle of "X^0 = 1," our logo encapsulates the fusion of technology and mathematical concepts. It embodies our mission to harness the power of both realms, creating innovative solutions that push boundaries and transcend the ordinary. Every time you see our logo, remember the story it tells – a tale of digital prowess intertwined with mathematical elegance, rooted in the very essence of how technology operates.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ourculture">
                <div className="container">
                    <div className="row pb-5">
                        <div className="col-12 text-center title">
                            <h3>Our culture</h3>
                            <p>Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque <br/>lorem. Tempus at odio libero cras eget est vestibulum vel nisi. </p>
                        </div>
                    </div>
                    <div className="row culture-img">
                        <div className="col-4">
                            <img src={require('../../../assets/cultureimg1.png')} alt="" />
                            <h4>Lorem ipsum dolor sit amet consectetur. Facilisis</h4>
                            <p>Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem. </p>
                        </div>
                        <div className="col-4 pt-5">
                            <img src={require('../../../assets/cultureimg2.png')} alt="" />
                            <h4>Lorem ipsum dolor sit amet consectetur. Facilisis</h4>
                            <p>Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem. </p>
                        </div>
                        <div className="col-4" style={{paddingTop:'6rem'}}>
                            <img src={require('../../../assets/cultureimg3.png')} alt="" />
                            <h4>Lorem ipsum dolor sit amet consectetur. Facilisis</h4>
                            <p>Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem. </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="serviceavail">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center title">
                            <h3>Our services are available for</h3>
                            <p>Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque <br/>lorem. Tempus at odio libero cras eget est vestibulum vel nisi. </p>
                        </div>
                    </div>
                    <div className="row serv-card-wrapper">
                        <div className="col-3">
                            <div className="business-card">
                                <img src={require('../../../assets/busicon1.png')} alt="" />
                                <p><b>Startups</b></p>
                                <p>Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem. Tempus at odio libero cras eget est vestibulum vel nisi. </p>     
                            </div>                   
                        </div>
                        <div className="col-3">
                            <div className="business-card">
                                <img src={require('../../../assets/busicon2.png')} alt="" />
                                <p><b>Small Business</b></p>
                                <p>Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem. Tempus at odio libero cras eget est vestibulum vel nisi. </p>     
                            </div>                   
                        </div>
                        <div className="col-3">
                            <div className="business-card">
                                <img src={require('../../../assets/busicon3.png')} alt="" />
                                <p><b>Mid Enterprises</b></p>
                                <p>Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem. Tempus at odio libero cras eget est vestibulum vel nisi. </p>     
                            </div>                   
                        </div>
                        <div className="col-3">
                            <div className="business-card">
                                <img src={require('../../../assets/busicon4.png')} alt="" />
                                <p><b>Enterprises</b></p>
                                <p>Blandit feugiat volutpat scelerisque lacinia varius justo felis turpis. Vitae accumsan tempor orci pellentesque lorem. Tempus at odio libero cras eget est vestibulum vel nisi. </p>     
                            </div>                   
                        </div>
                    </div>
                </div>
            </section>

            
            
            </>
    )
}

export default AboutPage;