import { images } from "../../../assets/images";

export const blogData = [
  {
    id:1,
    img: images.blog1,
    title: "Lorem ipsum dolor sit amet consectetur.",
    para:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, ipsum aenean imperdiet euismod. Scelerisque sagittis pharetra odio elit tortor habitant bibendum lacus non. Eget ac nec, ornare a, pharetra, tellus. ',
    author: "Olivia Rhyne",
    date: "20-Jan-2023",
  },
  {
    id:2,
    img: images.blog2,
    title: "Lorem ipsum dolor sit amet consectetur.",
    para:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, ipsum aenean imperdiet euismod. Scelerisque sagittis pharetra odio elit tortor habitant bibendum lacus non. Eget ac nec, ornare a, pharetra, tellus. ',
    author: "Olivia Rhyne",
    date: "20-Jan-2023",
  },
  {
    id:3,
    img: images.blog1,
    title: "Lorem ipsum dolor sit amet consectetur.",
    para:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, ipsum aenean imperdiet euismod. Scelerisque sagittis pharetra odio elit tortor habitant bibendum lacus non. Eget ac nec, ornare a, pharetra, tellus. ',
    author: "Olivia Rhyne",
    date: "20-Jan-2023",
  },
  {
    id:4,
    img: images.blog2,
    title: "Lorem ipsum dolor sit amet consectetur.",
    para:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, ipsum aenean imperdiet euismod. Scelerisque sagittis pharetra odio elit tortor habitant bibendum lacus non. Eget ac nec, ornare a, pharetra, tellus. ',
    author: "Olivia Rhyne",
    date: "20-Jan-2023",
  },
  {
    id:5,
    img: images.blog1,
    title: "Lorem ipsum dolor sit amet consectetur.",
    para:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, ipsum aenean imperdiet euismod. Scelerisque sagittis pharetra odio elit tortor habitant bibendum lacus non. Eget ac nec, ornare a, pharetra, tellus. ',
    author: "Olivia Rhyne",
    date: "20-Jan-2023",
  },
  {
    id:6,
    img: images.blog2,
    title: "Lorem ipsum dolor sit amet consectetur.",
    para:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, ipsum aenean imperdiet euismod. Scelerisque sagittis pharetra odio elit tortor habitant bibendum lacus non. Eget ac nec, ornare a, pharetra, tellus. ',
    author: "Olivia Rhyne",
    date: "20-Jan-2023",
  },
  {
    id:7,
    img: images.blog1,
    title: "Lorem ipsum dolor sit amet consectetur.",
    para:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, ipsum aenean imperdiet euismod. Scelerisque sagittis pharetra odio elit tortor habitant bibendum lacus non. Eget ac nec, ornare a, pharetra, tellus. ',
    author: "Olivia Rhyne",
    date: "20-Jan-2023",
  },
  {
    id:8,
    img: images.blog2,
    title: "Lorem ipsum dolor sit amet consectetur.",
    para:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi, ipsum aenean imperdiet euismod. Scelerisque sagittis pharetra odio elit tortor habitant bibendum lacus non. Eget ac nec, ornare a, pharetra, tellus. ',
    author: "Olivia Rhyne",
    date: "20-Jan-2023",
  },
];
