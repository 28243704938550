import React from "react";
import "./VerticalSubMenu.scss";
import CountUp from "../../../components/utils/CountUp/CountUp";
import { Link } from "react-router-dom";

export default function VerticalSubMenu(props) {
  return (
    <div class="row vertical-sub-menu m-0">
      <div
        class="nav flex-column col-3"
        id="v-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        {props.subMenu &&
          props.subMenu.map((menu) => {
            return (
              <button
                class={"nav-link " + (menu.isActive ? "show active" : "")}
                id={menu.id + "-tab"}
                data-bs-toggle="pill"
                data-bs-target={"#" + menu.id}
                type="button"
                role="tab"
                aria-controls={menu.id}
                aria-selected={"false"}
              >
                {menu.name}
              </button>
            );
          })}
      </div>

      <div class="tab-content col-6 p-0" id="v-tabContent">
        {props.subMenu &&
          props.subMenu.map((menu) => {
            return (
              <ul
                class={"tab-pane fade "+(menu.isActive ? "show active" : "")}
                id={menu.id}
                role="tabpanel"
                aria-labelledby={menu.id + "-tab"}
              >
                {menu.subMenu &&
                  menu.subMenu.map((sub) => {
                    return (
                      <li><Link to={sub.link} className="sub">
                        <img src={sub.icon} alt="" />
                        {sub.name}
                      </Link>
                      </li>
                    );
                  })}
              </ul>
            );
          })}
      </div>
      <div className="col-3 left-pane">
        <div className="content">
          <h4><CountUp endValue={60} />+</h4>
          <p>Running Projects</p>
        </div>
        <div className="content">
          <h4><CountUp endValue={120} />k</h4>
          <p>Projects Delivered</p>
        </div>
        <div className="content">
          <h4><CountUp endValue={20} />+</h4>
          <p>Country Served</p>
        </div>
        <div className="content">
          <h4><CountUp endValue={120} /></h4>
          <p>Happy Clients</p>
        </div>
      </div>
    </div>
  );
}
