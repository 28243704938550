import styled from "styled-components";
import { variant } from "styled-system";

export const StyledButton = styled.button(
  {
    cursor: "pointer",
    display: "inline-flex",
    padding: "20px 34px",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    borderRadius: "49px",
    border: "2px solid #151515",
    background: "#fff",
    color: "#000",
    fontFamily: "Poppins400",
    fontSize: "16px",
    lineHeight: "18.544px",
    backgroundColor: "transparent",
  },
  variant({
    variants: {
      orangeOutline: {
        padding: "20px 31px",
        gap: "4px",
        flexShrink: "0",
        border: "1px solid #e05a32",
        color: "#e05a32",
        textAlign: "center",
        fontFamily: "Rubik",
        fontWeight: "500",
        "& img": {
          filter:
            "invert(54%) sepia(96%) saturate(3983%) hue-rotate(344deg) brightness(94%) contrast(86%)",
        },
      },
      whiteFill: {
        backgroundColor: "#FFF",
      },
      orangeFill: {
        padding: "16px 24px",
        borderRadius: "36px",
        border: "1px solid #E05A32",
        backgroundColor: "#E05A32",
        color: "#FFF",
        textAlign: "center",
        fontFamily: "Roboto",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "19.8px",
        "& img": {
          filter:
            "invert(54%) sepia(96%) saturate(3983%) hue-rotate(344deg) brightness(94%) contrast(86%)",
        },
      },
    },
  })
);
