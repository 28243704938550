import React from "react";
import {
  Banner,
  About,
  Services,
  Workflow,
  Technologies,
  CurvedCarousel,
  Blog,
  Client,
  Branding,
  Industries,
  GrowBusiness,
  BannerAnimation,
  AboutCompany,
} from "../../../components/home";
import { images } from "../../../assets/images";
import styles from "./Home.module.scss";
import HeroSection from "../../../components/home/HeroSection/HeroSection";
import Testimonial from "../../../components/home/Testimonial/Testimonial";
import OurTeam from "../../../components/home/OurTeam/OurTeam";
import Stats from "../../../components/home/Stats/stats";
import Quotes from "../../../components/home/quotes/quotes";

export default function Home() {
  return (
    <>
      <img src={images.homeBannerBG} className={styles.GradientBG} alt="" />
      {/* <img src={images.HomeBlackBg} className={styles.BlackBG} alt="" /> */}
      {/* <Banner /> */}
      
      <HeroSection/>
      <div className={styles.fullBlackContainer}>
        {/* <About aos="fade-up" /> */}
        <AboutCompany/>
        <Quotes quote="Your one-stop brand empowerment hub, leveling the playing field with tailored strategies and a commitment to your success."/>
        {/* <Services aos="fade-up" /> */}
        <Workflow aos="fade-up" />
        <Quotes quote="Empowering businesses of any size to become formidable brands through comprehensive branding, marketing, and consulting solutions."/>
        {/* <Branding aos="fade-up" /> */}
        <Technologies aos="fade-up" />
        <Stats/>
        <Testimonial/>
        <CurvedCarousel aos="fade-up" />
        <Blog aos="fade-up" />
        {/* <Client aos="fade-up" /> */}
        {/* <Industries aos="fade-up" /> */}
        {/* <OurTeam/> */}
        {/* <GrowBusiness aos="fade-up" /> */}
      </div>
    </>
  );
}
