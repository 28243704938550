import React from "react";
import "./CurvedCarousel.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import { images } from "../../../assets/images";

export default function CurvedCarousel(props) {
  return (
    <div style={{position:'relative',padding :'100px 0',background:'#0f1011'}}>
      <div className="row mx-auto" style={{width:"35%"}}>
        <h4 className="title" style={{textAlign:'center'}}>
          Our Work
          
        </h4>
        <p className="text-center">
          See how we create 360° Value and help clients become the next and best
          versions of themselves.
        </p>
      </div>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={30}
        navigation={true}
        modules={[Navigation, Autoplay]}
        className="CurvedSwiper"
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide className="container3">
          <div className="container">
            <div className="row">
              <img src={images.work3} alt="" />
            </div>
            <div className="row">
              <div className="content animate__animated animate__fadeInUp">
                <h5>Social media design</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi,
                  ipsum aenean imperdiet
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="container4">
          <div className="container">
            <div className="row">
              <img src={images.work4} alt="" />
            </div>
            <div className="row">
              <div className="content animate__animated animate__fadeInUp">
                <h5>Social media design</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi,
                  ipsum aenean imperdiet
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="container5">
          <div className="container">
            <div className="row">
              <img src={images.work5} alt="" />
            </div>
            <div className="row">
              <div className="content animate__animated animate__fadeInUp">
                <h5>Social media design</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi,
                  ipsum aenean imperdiet
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="container2">
          <div className="container">
            <div className="row">
              <img src={images.work2} alt="" />
            </div>
            <div className="row">
              <div className="content animate__animated animate__fadeInUp">
                <h5>Social media design</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi,
                  ipsum aenean imperdiet
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="container1">
          <div className="container">
            <div className="row">
              <img src={images.work1} alt="" />
            </div>
            <div className="row">
              <div className="content animate__animated animate__fadeInUp">
                <h5>Social media design</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi,
                  ipsum aenean imperdiet
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="container3">
          <div className="container">
            <div className="row">
              <img src={images.work3} alt="" />
            </div>
            <div className="row">
              <div className="content animate__animated animate__fadeInUp">
                <h5>Social media design</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi,
                  ipsum aenean imperdiet
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="container4">
          <div className="container">
            <div className="row">
              <img src={images.work4} alt="" />
            </div>
            <div className="row">
              <div className="content animate__animated animate__fadeInUp">
                <h5>Social media design</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi,
                  ipsum aenean imperdiet
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="container5">
          <div className="container">
            <div className="row">
              <img src={images.work5} alt="" />
            </div>
            <div className="row">
              <div className="content animate__animated animate__fadeInUp">
                <h5>Social media design</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi,
                  ipsum aenean imperdiet
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="container2">
          <div className="container">
            <div className="row">
              <img src={images.work2} alt="" />
            </div>
            <div className="row">
              <div className="content animate__animated animate__fadeInUp">
                <h5>Social media design</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi,
                  ipsum aenean imperdiet
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="container1">
          <div className="container">
            <div className="row">
              <img src={images.work1} alt="" />
            </div>
            <div className="row">
              <div className="content animate__animated animate__fadeInUp">
                <h5>Social media design</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisi,
                  ipsum aenean imperdiet
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
