import React, { useEffect, useRef, useState } from "react";
import { useOnScreen } from "../useOnScreen";

export default function CountUp(props) {
  const counterEl = useRef(null);
  const isVisible = useOnScreen(counterEl);

  const [counter, setCounter] = useState(
    props.startValue ? props.startValue.toString() : "0"
  );
  const number = props.endValue.toString();
  const duration = props.duration ?? 200;

  useEffect(() => {
    if (isVisible) {
      let start = 0;
      const end = parseInt(number.substring(0, 3));
      if (start === end) return;

      let totalMilSecDur = parseInt(duration);
      let incrementTime = (totalMilSecDur / end) * 10;

      let timer = setInterval(() => {
        start += 1;
        setCounter(String(start) + number.substring(3));
        if (start === end) clearInterval(timer);
      }, incrementTime);
    }
  }, [number, duration, isVisible]);

  return <div ref={counterEl}>{counter}</div>;
}
