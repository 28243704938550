import { images } from "../../../assets/images";

export const ServicesData = [
  {
    id: 1,
    subId: "one",
    isActive: true,
    num: "01",
    icon: images.serviceIcon1,
    Title: "Graphics and UI/ UX Design",
    sub: [
      {
        name: "Logo Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Brochures and Flyers",
        redirectTo: "LogoDesgin",
      },
      {
        name: "UI/ UX Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Product Label Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Business card design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Explainer Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Animated Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Branding",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Company Profile",
        redirectTo: "LogoDesgin",
      },
    ],
  },
  {
    id: 2,
    subId: "two",
    num: "02",
    icon: images.serviceIcon2,
    Title: "Digital Marketing",
    sub: [
      {
        name: "Logo Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Brochures and Flyers",
        redirectTo: "LogoDesgin",
      },
      {
        name: "UI/ UX Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Product Label Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Business card design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Explainer Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Animated Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Branding",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Company Profile",
        redirectTo: "LogoDesgin",
      },
    ],
  },
  {
    id: 3,
    subId: "three",
    num: "03",
    icon: images.serviceIcon3,
    Title: "Mobile App Development",
    sub: [
      {
        name: "Logo Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Brochures and Flyers",
        redirectTo: "LogoDesgin",
      },
      {
        name: "UI/ UX Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Product Label Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Business card design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Explainer Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Animated Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Branding",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Company Profile",
        redirectTo: "LogoDesgin",
      },
    ],
  },
  {
    id: 4,
    subId: "four",
    num: "04",
    icon: images.serviceIcon4,
    Title: "Software development",
    sub: [
      {
        name: "Logo Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Brochures and Flyers",
        redirectTo: "LogoDesgin",
      },
      {
        name: "UI/ UX Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Product Label Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Business card design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Explainer Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Animated Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Branding",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Company Profile",
        redirectTo: "LogoDesgin",
      },
    ],
  },
  {
    id: 5,
    subId: "five",
    num: "05",
    icon: images.serviceIcon5,
    Title: "Web development",
    sub: [
      {
        name: "Logo Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Brochures and Flyers",
        redirectTo: "LogoDesgin",
      },
      {
        name: "UI/ UX Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Product Label Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Business card design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Explainer Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Animated Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Branding",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Company Profile",
        redirectTo: "LogoDesgin",
      },
    ],
  },
  {
    id: 6,
    subId: "six",
    num: "06",
    icon: images.serviceIcon6,
    Title: "Content creation",
    sub: [
      {
        name: "Logo Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Brochures and Flyers",
        redirectTo: "LogoDesgin",
      },
      {
        name: "UI/ UX Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Product Label Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Business card design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Explainer Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Animated Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Branding",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Company Profile",
        redirectTo: "LogoDesgin",
      },
    ],
  },
  {
    id: 7,
    subId: "seven",
    num: "07",
    icon: images.serviceIcon7,
    Title: "Virtual assistance (VA)",
    sub: [
      {
        name: "Logo Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Brochures and Flyers",
        redirectTo: "LogoDesgin",
      },
      {
        name: "UI/ UX Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Product Label Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Business card design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Explainer Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Animated Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Branding",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Company Profile",
        redirectTo: "LogoDesgin",
      },
    ],
  },
  {
    id: 8,
    subId: "eight",
    num: "08",
    icon: images.serviceIcon8,
    Title: "App & Web maintenance",
    sub: [
      {
        name: "Logo Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Brochures and Flyers",
        redirectTo: "LogoDesgin",
      },
      {
        name: "UI/ UX Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Product Label Design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Business card design",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Explainer Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Animated Videos",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Branding",
        redirectTo: "LogoDesgin",
      },
      {
        name: "Company Profile",
        redirectTo: "LogoDesgin",
      },
    ],
  },
  // {
  //   id: 9,
  //   subId: "nine",
  //   num: "09",
  //   icon: images.serviceIcon9,
  //   Title: "Branding",
  //   sub: [
  //     {
  //       name: "Logo Design",
  //       redirectTo: "LogoDesgin",
  //     },
  //     {
  //       name: "Brochures and Flyers",
  //       redirectTo: "LogoDesgin",
  //     },
  //     {
  //       name: "UI/ UX Design",
  //       redirectTo: "LogoDesgin",
  //     },
  //     {
  //       name: "Product Label Design",
  //       redirectTo: "LogoDesgin",
  //     },
  //     {
  //       name: "Business card design",
  //       redirectTo: "LogoDesgin",
  //     },
  //     {
  //       name: "Explainer Videos",
  //       redirectTo: "LogoDesgin",
  //     },
  //     {
  //       name: "Animated Videos",
  //       redirectTo: "LogoDesgin",
  //     },
  //     {
  //       name: "Branding",
  //       redirectTo: "LogoDesgin",
  //     },
  //     {
  //       name: "Company Profile",
  //       redirectTo: "LogoDesgin",
  //     },
  //   ],
  // },
];
