import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

import "animate.css";
import Router from "./Router/Router";
import AOS from "aos";
import "aos/dist/aos.css";
import "./hover.scss";

function App() {
  return <Router />;
}

AOS.init({
  duration: 1400,
});

export default App;
