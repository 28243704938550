import React from "react";
import './AboutCompany.css';

const AboutCompany = () =>{
    return(
        <div className="about-company container"  style={{position:'relative'}}>
            <div className="row">
                <div className="col-6 d-flex align-items-center">
                    {/* <div style={{position:"absolute",fontSize:'300px',opacity:'0.1',fontWeight:'600',zIndex:'0',top:'-50px',left:'-100px'}}>About <div style={{position:"absolute",right:'-80px',top:'75px',fontSize:'80px'}}>Us</div></div> */}
                        
                    <div className="content" style={{position:"relative",zIndex:'1'}}>
                        <h3>Who we are</h3>
                        <p>We’re just like you. We know what it’s like to build a product, and dream of taking it to the next stage. We know what it’s like to take a huge leap of faith. Most of all, we know business isn’t something that you should have to figure out on your own. So, we’re here to help you. We’ll help you thrive in an increasingly complex landscape.</p>
                        <button className="blue-btn" >Learn More</button>
                    </div>
                </div>
                
                <div className="col-6 about-com-img">
                    <img src={require('../../../assets/Home/aboutimg1.png')} alt="whatwedo"/>
                </div>

            </div>
        </div>
    )
}

export default AboutCompany;