import React from 'react';
import './style.css';

const Stats=()=>{
    return(
        <section className='stats-section'>
            <div className="container">
                <div className="d-flex gap-5 xl-screen" style={{justifyContent:'space-between'}}>
                    <div className="wrapper">
                        <div>   
                            <img src={require('../../../assets/Home/staticon1.png')} alt="" />                
                            <h3>500+</h3>
                            <h6>Satisfied clients</h6>
                        </div> 
                    </div>
                    <div className="wrapper">
                        <div>
                            <img src={require('../../../assets/Home/staticon2.png')} alt="" />     
                            <h3>$10m</h3>
                            <h6>Revenue Generated For clients</h6>
                        </div>

                    </div>
                    <div className="wrapper">
                        <div>
                            <img src={require('../../../assets/Home/staticon3.png')} alt="" />     
                            <h3>800+</h3>
                            <h6>Projects delivered</h6>
                        </div>

                    </div>
                    <div className="wrapper">
                        <div>
                            <img src={require('../../../assets/Home/staticon4.png')} alt="" />     
                            <h3>20+</h3>
                            <h6>Countries served</h6>
                        </div>

                    </div>
                    <div className="wrapper">
                        <div>
                            <img src={require('../../../assets/Home/staticon5.png')} alt="" />     
                            <h3>99%</h3>
                            <h6>Success score</h6>
                        </div>

                    </div>
                </div>
                <div className="d-flex gap-5 md-screen" style={{flexFlow:'wrap', justifyContent:'center'}} >
                    <div className="wrapper">
                        <div>   
                            <img src={require('../../../assets/Home/staticon1.png')} alt="" />                
                            <h3>500+</h3>
                            <h6>Satisfied clients</h6>
                        </div> 
                    </div>
                    <div className="wrapper">
                        <div>
                            <img src={require('../../../assets/Home/staticon2.png')} alt="" />     
                            <h3>$10m</h3>
                            <h6>Revenue Generated For clients</h6>
                        </div>
                    </div>
                    <div className="wrapper">
                        <div>
                            <img src={require('../../../assets/Home/staticon3.png')} alt="" />     
                            <h3>800+</h3>
                            <h6>Projects delivered</h6>
                        </div>

                    </div>

                    <div className="wrapper">
                        <div>
                            <img src={require('../../../assets/Home/staticon4.png')} alt="" />     
                            <h3>20+</h3>
                            <h6>Countries served</h6>
                        </div>

                    </div>
                    <div className="wrapper">
                        <div>
                            <img src={require('../../../assets/Home/staticon5.png')} alt="" />     
                            <h3>99%</h3>
                            <h6>Success score</h6>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Stats;