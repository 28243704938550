import { images } from "../../../assets/images";

export const timing = [
  "10:00 Am",
  "11:00 Am",
  "12:00 Am",
  "01:00 Pm",
  "03:00 Am",
  "04:00 Pm",
  "05:00 Pm",
  "06:00 Pm",
];

export const ServicesList = [
  {
    id: 11,
    isActive: true,
    name: "Digital Marketing",
    link: "/service/digital-marketing",
    subMenu: [
      {
        id: 1,
        name: "Ad Campaign",
        icon: images.adcampaign,
        link:"adcampaign"
      },
      {
        id: 2,
        name: "Social Media Optimization",
        icon: images.smo,
        link:"socialmediaoptimization"
      },
      {
        id: 3,
        name: "Search Engine Optimization",
        icon: images.seo,
        link:"searchengineoptimization"
      },
      {
        id: 4,
        name: "Amazon Marketing",
        icon: images.amazon,
        link:"amazonmarketing"
      },
      {
        id: 5,
        name: "Paid marketing",
        icon: images.paidMarketing,
        link:"paidmarketing"
      },
      {
        id: 6,
        name: "Influncer marketing",
        icon: images.influencer,
        link:"influencermarketing"
      },
      {
        id: 7,
        name: "Network/Referral marketing",
        icon: images.network,
        link:"network"
      },
      {
        id: 8,
        name: "White Label Service",
        icon: images.whiteLabelService,
        link:"whiteLabelService"
      },
      {
        id: 9,
        name: "Google Analatics",
        icon: images.googleAnalyticsServices,
        link:"googleanalyticsservices"
      },
    ],
  },
  {
    id: 2,
    name: "Web design and development",
    link: "",
    subMenu: [
      {
        id: 1,
        name: "Custom Web Design",
        icon: (
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 0C6.55228 0 7 0.44772 7 1C7 1.51283 6.61396 1.93551 6.11662 1.99327L6 2H5C4.48717 2 4.06449 2.38604 4.00673 2.88338L4 3V7C4 7.7684 3.71115 8.4692 3.2361 9C3.66365 9.47772 3.94037 10.0931 3.99144 10.7716L4 11V15C4 15.5128 4.38604 15.9355 4.88338 15.9933L5 16H6C6.55228 16 7 16.4477 7 17C7 17.5128 6.61396 17.9355 6.11662 17.9933L6 18H5C3.40232 18 2.09634 16.7511 2.00509 15.1763L2 15V11C2 10.4872 1.61396 10.0645 1.11662 10.0067L1 10C0.44772 10 0 9.5523 0 9C0 8.48715 0.386044 8.06449 0.88338 8.00673L1 8C1.51283 8 1.93551 7.61397 1.99327 7.11662L2 7V3C2 1.40232 3.24892 0.0963393 4.82373 0.00509262L5 0H6ZM15 0C16.5977 0 17.9037 1.24892 17.9949 2.82373L18 3V7C18 7.51285 18.386 7.93551 18.8834 7.99327L19 8C19.5523 8 20 8.4477 20 9C20 9.51285 19.614 9.93551 19.1166 9.99327L19 10C18.4872 10 18.0645 10.386 18.0067 10.8834L18 11V15C18 16.5977 16.7511 17.9037 15.1763 17.9949L15 18H14C13.4477 18 13 17.5523 13 17C13 16.4872 13.386 16.0645 13.8834 16.0067L14 16H15C15.5128 16 15.9355 15.614 15.9933 15.1166L16 15V11C16 10.2316 16.2889 9.5308 16.7639 9C16.3364 8.52228 16.0596 7.90686 16.0086 7.22839L16 7V3C16 2.48717 15.614 2.06449 15.1166 2.00673L15 2H14C13.4477 2 13 1.55228 13 1C13 0.487169 13.386 0.0644934 13.8834 0.0067277L14 0H15Z"
              fill="#000"
            />
          </svg>
        ),
        link:"customwebdesign"
      },
      {
        id: 2,
        name: "Wordpress website design",
        icon: (
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 0C6.55228 0 7 0.44772 7 1C7 1.51283 6.61396 1.93551 6.11662 1.99327L6 2H5C4.48717 2 4.06449 2.38604 4.00673 2.88338L4 3V7C4 7.7684 3.71115 8.4692 3.2361 9C3.66365 9.47772 3.94037 10.0931 3.99144 10.7716L4 11V15C4 15.5128 4.38604 15.9355 4.88338 15.9933L5 16H6C6.55228 16 7 16.4477 7 17C7 17.5128 6.61396 17.9355 6.11662 17.9933L6 18H5C3.40232 18 2.09634 16.7511 2.00509 15.1763L2 15V11C2 10.4872 1.61396 10.0645 1.11662 10.0067L1 10C0.44772 10 0 9.5523 0 9C0 8.48715 0.386044 8.06449 0.88338 8.00673L1 8C1.51283 8 1.93551 7.61397 1.99327 7.11662L2 7V3C2 1.40232 3.24892 0.0963393 4.82373 0.00509262L5 0H6ZM15 0C16.5977 0 17.9037 1.24892 17.9949 2.82373L18 3V7C18 7.51285 18.386 7.93551 18.8834 7.99327L19 8C19.5523 8 20 8.4477 20 9C20 9.51285 19.614 9.93551 19.1166 9.99327L19 10C18.4872 10 18.0645 10.386 18.0067 10.8834L18 11V15C18 16.5977 16.7511 17.9037 15.1763 17.9949L15 18H14C13.4477 18 13 17.5523 13 17C13 16.4872 13.386 16.0645 13.8834 16.0067L14 16H15C15.5128 16 15.9355 15.614 15.9933 15.1166L16 15V11C16 10.2316 16.2889 9.5308 16.7639 9C16.3364 8.52228 16.0596 7.90686 16.0086 7.22839L16 7V3C16 2.48717 15.614 2.06449 15.1166 2.00673L15 2H14C13.4477 2 13 1.55228 13 1C13 0.487169 13.386 0.0644934 13.8834 0.0067277L14 0H15Z"
              fill="#000"
            />
          </svg>
        ),
        link:"wordpresswebsitedesign"
      },
      {
        id: 3,
        name: "E-commerce website",
        icon: (
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 0C6.55228 0 7 0.44772 7 1C7 1.51283 6.61396 1.93551 6.11662 1.99327L6 2H5C4.48717 2 4.06449 2.38604 4.00673 2.88338L4 3V7C4 7.7684 3.71115 8.4692 3.2361 9C3.66365 9.47772 3.94037 10.0931 3.99144 10.7716L4 11V15C4 15.5128 4.38604 15.9355 4.88338 15.9933L5 16H6C6.55228 16 7 16.4477 7 17C7 17.5128 6.61396 17.9355 6.11662 17.9933L6 18H5C3.40232 18 2.09634 16.7511 2.00509 15.1763L2 15V11C2 10.4872 1.61396 10.0645 1.11662 10.0067L1 10C0.44772 10 0 9.5523 0 9C0 8.48715 0.386044 8.06449 0.88338 8.00673L1 8C1.51283 8 1.93551 7.61397 1.99327 7.11662L2 7V3C2 1.40232 3.24892 0.0963393 4.82373 0.00509262L5 0H6ZM15 0C16.5977 0 17.9037 1.24892 17.9949 2.82373L18 3V7C18 7.51285 18.386 7.93551 18.8834 7.99327L19 8C19.5523 8 20 8.4477 20 9C20 9.51285 19.614 9.93551 19.1166 9.99327L19 10C18.4872 10 18.0645 10.386 18.0067 10.8834L18 11V15C18 16.5977 16.7511 17.9037 15.1763 17.9949L15 18H14C13.4477 18 13 17.5523 13 17C13 16.4872 13.386 16.0645 13.8834 16.0067L14 16H15C15.5128 16 15.9355 15.614 15.9933 15.1166L16 15V11C16 10.2316 16.2889 9.5308 16.7639 9C16.3364 8.52228 16.0596 7.90686 16.0086 7.22839L16 7V3C16 2.48717 15.614 2.06449 15.1166 2.00673L15 2H14C13.4477 2 13 1.55228 13 1C13 0.487169 13.386 0.0644934 13.8834 0.0067277L14 0H15Z"
              fill="#000"
            />
          </svg>
        ),
        link:"ecommercewebsite"
      },
      {
        id: 4,
        name: "ADA compliance service",
        icon: (
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 0C6.55228 0 7 0.44772 7 1C7 1.51283 6.61396 1.93551 6.11662 1.99327L6 2H5C4.48717 2 4.06449 2.38604 4.00673 2.88338L4 3V7C4 7.7684 3.71115 8.4692 3.2361 9C3.66365 9.47772 3.94037 10.0931 3.99144 10.7716L4 11V15C4 15.5128 4.38604 15.9355 4.88338 15.9933L5 16H6C6.55228 16 7 16.4477 7 17C7 17.5128 6.61396 17.9355 6.11662 17.9933L6 18H5C3.40232 18 2.09634 16.7511 2.00509 15.1763L2 15V11C2 10.4872 1.61396 10.0645 1.11662 10.0067L1 10C0.44772 10 0 9.5523 0 9C0 8.48715 0.386044 8.06449 0.88338 8.00673L1 8C1.51283 8 1.93551 7.61397 1.99327 7.11662L2 7V3C2 1.40232 3.24892 0.0963393 4.82373 0.00509262L5 0H6ZM15 0C16.5977 0 17.9037 1.24892 17.9949 2.82373L18 3V7C18 7.51285 18.386 7.93551 18.8834 7.99327L19 8C19.5523 8 20 8.4477 20 9C20 9.51285 19.614 9.93551 19.1166 9.99327L19 10C18.4872 10 18.0645 10.386 18.0067 10.8834L18 11V15C18 16.5977 16.7511 17.9037 15.1763 17.9949L15 18H14C13.4477 18 13 17.5523 13 17C13 16.4872 13.386 16.0645 13.8834 16.0067L14 16H15C15.5128 16 15.9355 15.614 15.9933 15.1166L16 15V11C16 10.2316 16.2889 9.5308 16.7639 9C16.3364 8.52228 16.0596 7.90686 16.0086 7.22839L16 7V3C16 2.48717 15.614 2.06449 15.1166 2.00673L15 2H14C13.4477 2 13 1.55228 13 1C13 0.487169 13.386 0.0644934 13.8834 0.0067277L14 0H15Z"
              fill="#000"
            />
          </svg>
        ),
        link:"adacomplianceservice"
      },
      {
        id: 5,
        name: "Video production",
        icon: (
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 0C6.55228 0 7 0.44772 7 1C7 1.51283 6.61396 1.93551 6.11662 1.99327L6 2H5C4.48717 2 4.06449 2.38604 4.00673 2.88338L4 3V7C4 7.7684 3.71115 8.4692 3.2361 9C3.66365 9.47772 3.94037 10.0931 3.99144 10.7716L4 11V15C4 15.5128 4.38604 15.9355 4.88338 15.9933L5 16H6C6.55228 16 7 16.4477 7 17C7 17.5128 6.61396 17.9355 6.11662 17.9933L6 18H5C3.40232 18 2.09634 16.7511 2.00509 15.1763L2 15V11C2 10.4872 1.61396 10.0645 1.11662 10.0067L1 10C0.44772 10 0 9.5523 0 9C0 8.48715 0.386044 8.06449 0.88338 8.00673L1 8C1.51283 8 1.93551 7.61397 1.99327 7.11662L2 7V3C2 1.40232 3.24892 0.0963393 4.82373 0.00509262L5 0H6ZM15 0C16.5977 0 17.9037 1.24892 17.9949 2.82373L18 3V7C18 7.51285 18.386 7.93551 18.8834 7.99327L19 8C19.5523 8 20 8.4477 20 9C20 9.51285 19.614 9.93551 19.1166 9.99327L19 10C18.4872 10 18.0645 10.386 18.0067 10.8834L18 11V15C18 16.5977 16.7511 17.9037 15.1763 17.9949L15 18H14C13.4477 18 13 17.5523 13 17C13 16.4872 13.386 16.0645 13.8834 16.0067L14 16H15C15.5128 16 15.9355 15.614 15.9933 15.1166L16 15V11C16 10.2316 16.2889 9.5308 16.7639 9C16.3364 8.52228 16.0596 7.90686 16.0086 7.22839L16 7V3C16 2.48717 15.614 2.06449 15.1166 2.00673L15 2H14C13.4477 2 13 1.55228 13 1C13 0.487169 13.386 0.0644934 13.8834 0.0067277L14 0H15Z"
              fill="#000"
            />
          </svg>
        ),
        link:"videoproduction"
      },
      {
        id: 6,
        name: "Email marketing services",
        icon: (
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 0C6.55228 0 7 0.44772 7 1C7 1.51283 6.61396 1.93551 6.11662 1.99327L6 2H5C4.48717 2 4.06449 2.38604 4.00673 2.88338L4 3V7C4 7.7684 3.71115 8.4692 3.2361 9C3.66365 9.47772 3.94037 10.0931 3.99144 10.7716L4 11V15C4 15.5128 4.38604 15.9355 4.88338 15.9933L5 16H6C6.55228 16 7 16.4477 7 17C7 17.5128 6.61396 17.9355 6.11662 17.9933L6 18H5C3.40232 18 2.09634 16.7511 2.00509 15.1763L2 15V11C2 10.4872 1.61396 10.0645 1.11662 10.0067L1 10C0.44772 10 0 9.5523 0 9C0 8.48715 0.386044 8.06449 0.88338 8.00673L1 8C1.51283 8 1.93551 7.61397 1.99327 7.11662L2 7V3C2 1.40232 3.24892 0.0963393 4.82373 0.00509262L5 0H6ZM15 0C16.5977 0 17.9037 1.24892 17.9949 2.82373L18 3V7C18 7.51285 18.386 7.93551 18.8834 7.99327L19 8C19.5523 8 20 8.4477 20 9C20 9.51285 19.614 9.93551 19.1166 9.99327L19 10C18.4872 10 18.0645 10.386 18.0067 10.8834L18 11V15C18 16.5977 16.7511 17.9037 15.1763 17.9949L15 18H14C13.4477 18 13 17.5523 13 17C13 16.4872 13.386 16.0645 13.8834 16.0067L14 16H15C15.5128 16 15.9355 15.614 15.9933 15.1166L16 15V11C16 10.2316 16.2889 9.5308 16.7639 9C16.3364 8.52228 16.0596 7.90686 16.0086 7.22839L16 7V3C16 2.48717 15.614 2.06449 15.1166 2.00673L15 2H14C13.4477 2 13 1.55228 13 1C13 0.487169 13.386 0.0644934 13.8834 0.0067277L14 0H15Z"
              fill="#000"
            />
          </svg>
        ),
        link:"emailmarketingservices"
      },
      {
        id: 7,
        name: "Website Hosting",
        icon: (
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 0C6.55228 0 7 0.44772 7 1C7 1.51283 6.61396 1.93551 6.11662 1.99327L6 2H5C4.48717 2 4.06449 2.38604 4.00673 2.88338L4 3V7C4 7.7684 3.71115 8.4692 3.2361 9C3.66365 9.47772 3.94037 10.0931 3.99144 10.7716L4 11V15C4 15.5128 4.38604 15.9355 4.88338 15.9933L5 16H6C6.55228 16 7 16.4477 7 17C7 17.5128 6.61396 17.9355 6.11662 17.9933L6 18H5C3.40232 18 2.09634 16.7511 2.00509 15.1763L2 15V11C2 10.4872 1.61396 10.0645 1.11662 10.0067L1 10C0.44772 10 0 9.5523 0 9C0 8.48715 0.386044 8.06449 0.88338 8.00673L1 8C1.51283 8 1.93551 7.61397 1.99327 7.11662L2 7V3C2 1.40232 3.24892 0.0963393 4.82373 0.00509262L5 0H6ZM15 0C16.5977 0 17.9037 1.24892 17.9949 2.82373L18 3V7C18 7.51285 18.386 7.93551 18.8834 7.99327L19 8C19.5523 8 20 8.4477 20 9C20 9.51285 19.614 9.93551 19.1166 9.99327L19 10C18.4872 10 18.0645 10.386 18.0067 10.8834L18 11V15C18 16.5977 16.7511 17.9037 15.1763 17.9949L15 18H14C13.4477 18 13 17.5523 13 17C13 16.4872 13.386 16.0645 13.8834 16.0067L14 16H15C15.5128 16 15.9355 15.614 15.9933 15.1166L16 15V11C16 10.2316 16.2889 9.5308 16.7639 9C16.3364 8.52228 16.0596 7.90686 16.0086 7.22839L16 7V3C16 2.48717 15.614 2.06449 15.1166 2.00673L15 2H14C13.4477 2 13 1.55228 13 1C13 0.487169 13.386 0.0644934 13.8834 0.0067277L14 0H15Z"
              fill="#000"
            />
          </svg>
        ),
        link:"websitehosting"
      },
    ],
  },
  {
    id: 3,
    name: "Mobile app development",
    link: "",
    subMenu: [
      {
        id: 1,
        name: "Web Design",
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_426_1166)">
              <path
                d="M17 2C18.0544 2 18.9182 2.81588 18.9945 3.85074L19 4V20C19 21.0544 18.1841 21.9182 17.1493 21.9945L17 22H7C5.94564 22 5.08183 21.1841 5.00549 20.1493L5 20V4C5 2.94564 5.81588 2.08183 6.85074 2.00549L7 2H17ZM17 4H7V20H17V4ZM12.5 16C12.7454 16 12.9496 16.1769 12.9919 16.4101L13 16.5V17.5C13 17.7454 12.8231 17.9496 12.5899 17.9919L12.5 18H11.5C11.2546 18 11.0504 17.8231 11.0081 17.5899L11 17.5V16.5C11 16.2546 11.1769 16.0504 11.4101 16.0081L11.5 16H12.5Z"
                fill="#000"
              />
            </g>
            <defs>
              <clipPath id="clip0_426_1166">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ),
        link:"webdesign"
      },
      {
        id: 2,
        name: "Static website",
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_426_1166)">
              <path
                d="M17 2C18.0544 2 18.9182 2.81588 18.9945 3.85074L19 4V20C19 21.0544 18.1841 21.9182 17.1493 21.9945L17 22H7C5.94564 22 5.08183 21.1841 5.00549 20.1493L5 20V4C5 2.94564 5.81588 2.08183 6.85074 2.00549L7 2H17ZM17 4H7V20H17V4ZM12.5 16C12.7454 16 12.9496 16.1769 12.9919 16.4101L13 16.5V17.5C13 17.7454 12.8231 17.9496 12.5899 17.9919L12.5 18H11.5C11.2546 18 11.0504 17.8231 11.0081 17.5899L11 17.5V16.5C11 16.2546 11.1769 16.0504 11.4101 16.0081L11.5 16H12.5Z"
                fill="#000"
              />
            </g>
            <defs>
              <clipPath id="clip0_426_1166">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ),
        link:"staticwebsite"
      },
      {
        id: 3,
        name: "E-commerce website",
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_426_1166)">
              <path
                d="M17 2C18.0544 2 18.9182 2.81588 18.9945 3.85074L19 4V20C19 21.0544 18.1841 21.9182 17.1493 21.9945L17 22H7C5.94564 22 5.08183 21.1841 5.00549 20.1493L5 20V4C5 2.94564 5.81588 2.08183 6.85074 2.00549L7 2H17ZM17 4H7V20H17V4ZM12.5 16C12.7454 16 12.9496 16.1769 12.9919 16.4101L13 16.5V17.5C13 17.7454 12.8231 17.9496 12.5899 17.9919L12.5 18H11.5C11.2546 18 11.0504 17.8231 11.0081 17.5899L11 17.5V16.5C11 16.2546 11.1769 16.0504 11.4101 16.0081L11.5 16H12.5Z"
                fill="#000"
              />
            </g>
            <defs>
              <clipPath id="clip0_426_1166">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ),
        link:"ecommercewebsite"
      },

      {
        id: 4,
        name: "Dynamic website",
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_426_1166)">
              <path
                d="M17 2C18.0544 2 18.9182 2.81588 18.9945 3.85074L19 4V20C19 21.0544 18.1841 21.9182 17.1493 21.9945L17 22H7C5.94564 22 5.08183 21.1841 5.00549 20.1493L5 20V4C5 2.94564 5.81588 2.08183 6.85074 2.00549L7 2H17ZM17 4H7V20H17V4ZM12.5 16C12.7454 16 12.9496 16.1769 12.9919 16.4101L13 16.5V17.5C13 17.7454 12.8231 17.9496 12.5899 17.9919L12.5 18H11.5C11.2546 18 11.0504 17.8231 11.0081 17.5899L11 17.5V16.5C11 16.2546 11.1769 16.0504 11.4101 16.0081L11.5 16H12.5Z"
                fill="#000"
              />
            </g>
            <defs>
              <clipPath id="clip0_426_1166">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ),
        link:"dynamicwebsite"
      },
      {
        id: 5,
        name: "Landing pages",
        icon: (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_426_1166)">
              <path
                d="M17 2C18.0544 2 18.9182 2.81588 18.9945 3.85074L19 4V20C19 21.0544 18.1841 21.9182 17.1493 21.9945L17 22H7C5.94564 22 5.08183 21.1841 5.00549 20.1493L5 20V4C5 2.94564 5.81588 2.08183 6.85074 2.00549L7 2H17ZM17 4H7V20H17V4ZM12.5 16C12.7454 16 12.9496 16.1769 12.9919 16.4101L13 16.5V17.5C13 17.7454 12.8231 17.9496 12.5899 17.9919L12.5 18H11.5C11.2546 18 11.0504 17.8231 11.0081 17.5899L11 17.5V16.5C11 16.2546 11.1769 16.0504 11.4101 16.0081L11.5 16H12.5Z"
                fill="#000"
              />
            </g>
            <defs>
              <clipPath id="clip0_426_1166">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ),
        link:"landingpages"
      },
    ],
  },
  {
    id: 4,
    name: "Graphic & UI/UX design",
    link: "",
    subMenu: [],
  },
  {
    id: 5,
    name: "Virtual assistance ",
    link: "",
    subMenu: [],
  },
  {
    id: 6,
    name: "Content creation",
    link: "",
    subMenu: [],
  },
  {
    id: 7,
    name: "App & web maintenance",
    link: "",
    subMenu: [],
  },
];

export const CompanyList = [
  {
    id: 1,
    isActive: true,
    name: "About us",
    link: "",
    subMenu: [
      {
        id: 1,
        name: "Who we are",
        link:"whoweare"
      },
      {
        id: 2,
        name: "Our Value",
        link:"ourvalue"
      },
      {
        id: 3,
        name: "Why choose us",
        link:"whychooseus"
      },
    ],
  },
];
