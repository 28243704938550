import Logo from "./logo.png";
import logoWhite from "./logoWhite.svg";
import MenuIcon from "./MenuIcon.svg";
import Desiging from "./Desiging.svg";
import digitalMargeting from "./digitalMargeting.svg";
import computer from "./computer.svg";
import ArrowRight from "./ArrowRight.svg";
import ArrowLeft from "./ArrowLeft.svg";
import sliderLeftBtn from "./sliderLeftBtn.png";
import sliderRightBtn from "./sliderRightBtn.png";
import facebook from "./facebook.png";
import linkedin from "./linkedin.png";
import instagram from "./instagram.png";
import twitter from "./twitter.png";
import hourglass from "./hourglass.png";
import timer from "./timer.png";
import calendar from "./calendar.png";
import PinkBlueGradient from "./PinkBlueGradient.svg";
import BluePinkGradient from "./BluePinkGradient.svg";
import { home } from "./Home";
import { header } from "./Header";
import arrowNext from "./arrowNext.svg";
import arrowPrev from "./arrowPrev.svg";
import arrowNextHover from "./arrowNextHover.svg";
import arrowPrevHover from "./arrowPrevHover.svg";
import aboutbanner from './aboutbanner.png';
import ourstoryimg from './teamimagestory.png';
import storyicon1 from './icon1.png';
import storyicon2 from './icon2.png';
import storyicon3 from './icon3.png';
import cultureimg1 from './culture1.png';
import cultureimg2 from './culture2.png';
import cultureimg3 from './culture3.png';
import cultureimg4 from './culture4.png';
import cultureimg5 from './culture5.png';
import carreer1 from './carreer1.png';
import carreer2 from './carreer2.png';
import intershipProgram from './intership-program.png';
import techimgbanner from './techimg1.png';

export const images = {
  ...home,
  ...header,
  Logo,
  MenuIcon,
  Desiging,
  digitalMargeting,
  computer,
  ArrowRight,
  ArrowLeft,
  sliderLeftBtn,
  sliderRightBtn,
  facebook,
  linkedin,
  instagram,
  twitter,
  hourglass,
  timer,
  calendar,
  PinkBlueGradient,
  logoWhite,
  arrowNext,
  arrowPrev,
  arrowNextHover,
  arrowPrevHover,
  BluePinkGradient,
  aboutbanner,
  ourstoryimg,
  storyicon1,
  storyicon2,
  storyicon3,
  cultureimg1,
  cultureimg2,
  cultureimg3,
  cultureimg4,
  cultureimg5,
  carreer1,
  carreer2,intershipProgram, techimgbanner, 
};
