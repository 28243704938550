import React from "react";
import './style.scss';

const Servicepage = () =>{
    return(
            <>
                <section className="about-banner-m">
                    <div className="container pt-5">
                        <div className="row">
                            <div className="col-sm-4 col-12">
                                             
                            </div>
                            <div className="col-sm-8 col-12" style={{height:'350px', display:'flex', alignItems:'end'}}>      
                                <div>               
                                <h2>Your golden ticket to the creative world</h2>
                                <h6 style={{fontSize:'30px'}}>of custom design and branding.</h6>
                                </div>                          
                            </div>
                        </div>
                    </div>
                </section>

                <section className="featuresection">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center title">
                                <h3>If you can imagine it,<br/> we can design it </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="glowing-card">
                                    <div className="d-flex gap-4 align-items-center">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.9998 10.6667C19.6462 10.6668 19.3071 10.8073 19.0571 11.0573C18.807 11.3074 18.6665 11.6465 18.6665 12.0001V17.3334C18.6665 18.0951 18.0949 18.6667 17.3332 18.6667H11.9998C11.6462 18.6668 11.3071 18.8073 11.0571 19.0573C10.807 19.3074 10.6665 19.6465 10.6665 20.0001V25.3334C10.6665 27.5264 12.4736 29.3334 14.6665 29.3334H25.3332C27.5261 29.3334 29.3332 27.5264 29.3332 25.3334V14.6667C29.3332 12.4738 27.5261 10.6667 25.3332 10.6667H19.9998ZM21.3332 13.3334H25.3332C26.0949 13.3334 26.6665 13.905 26.6665 14.6667V25.3334C26.6665 26.0951 26.0949 26.6667 25.3332 26.6667H14.6665C13.9048 26.6667 13.3332 26.0951 13.3332 25.3334V21.3334H17.3332C19.5261 21.3334 21.3332 19.5264 21.3332 17.3334V13.3334Z" fill="white"/>
                                        <path d="M6.66354 2.66724C4.47061 2.66724 2.66748 4.47036 2.66748 6.6633V17.3326C2.66748 19.5255 4.47061 21.3287 6.66354 21.3287H17.3328C19.5258 21.3287 21.3289 19.5255 21.3289 17.3326V6.6633C21.3289 4.47036 19.5258 2.66724 17.3328 2.66724H6.66354ZM6.66354 5.33456H17.3328C18.0946 5.33456 18.6714 5.90157 18.6714 6.6633V17.3326C18.6714 18.0943 18.0946 18.6712 17.3328 18.6712H6.66354C5.90181 18.6712 5.3348 18.0943 5.3348 17.3326V6.6633C5.3348 5.90157 5.90181 5.33456 6.66354 5.33456Z" fill="white"/>
                                        </svg>
                                        <h3>UI/UX Design</h3>

                                    </div>
                                    <ul>
                                        <li>Landing pages</li>
                                        <li>Multi-page sites</li>
                                        <li>Marketplaces</li>
                                        <li>Mobile and Web Apps</li>
                                        <li>Product design</li>
                                        <li>Layout of sites on no code platforms</li>
                                        <li>Creation email templates</li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-6">
                                <div className="glowing-card">
                                    <div className="d-flex gap-4 align-items-center">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.8001 20.8001C25.1001 16.5001 25.1001 9.5001 20.8001 5.2001C16.5001 0.900098 9.5001 0.900098 5.2001 5.2001C0.900098 9.5001 0.900098 16.5001 5.2001 20.8001C7.3001 22.9001 10.2001 24.0001 13.0001 24.0001C15.8001 24.0001 18.6001 22.9001 20.8001 20.8001Z" fill="white"/>
                                        <path d="M18.8999 24.6001L23.3999 29.1001C24.2999 28.7001 25.1999 28.2001 25.9999 27.5001L21.3999 22.9001C20.6999 23.5001 19.7999 24.1001 18.8999 24.6001Z" fill="white"/>
                                        <path d="M13.0003 26.0001C12.1003 26.0001 11.2003 25.9001 10.3003 25.7001C10.6003 26.1001 10.9003 26.4001 11.2003 26.8001C13.3003 28.9001 16.2003 30.0001 19.0003 30.0001C19.8003 30.0001 20.5003 29.9001 21.3003 29.8001L17.0003 25.4001C15.7003 25.8001 14.4003 26.0001 13.0003 26.0001Z" fill="white"/>
                                        <path d="M26.7999 11.2C26.4999 10.9 26.0999 10.6 25.6999 10.3C26.1999 12.5 26.0999 14.8 25.3999 16.9L29.7999 21.2C30.4999 17.8 29.4999 13.9 26.7999 11.2Z" fill="white"/>
                                        <path d="M22.8999 21.4001L27.4999 26.0001C28.1999 25.2001 28.6999 24.3001 29.0999 23.4001L24.5999 18.9001C24.0999 19.8001 23.4999 20.7001 22.8999 21.4001Z" fill="white"/>
                                        </svg>

                                        <h3>Graphic Design</h3>

                                    </div>
                                    <ul>
                                        <li>Landing pages</li>
                                        <li>Multi-page sites</li>
                                        <li>Marketplaces</li>
                                        <li>Mobile and Web Apps</li>
                                        <li>Product design</li>
                                        <li>Layout of sites on no code platforms</li>
                                        <li>Creation email templates</li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-6">
                                <div className="glowing-card">
                                    <div className="d-flex gap-4 align-items-center">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.9998 10.6667C19.6462 10.6668 19.3071 10.8073 19.0571 11.0573C18.807 11.3074 18.6665 11.6465 18.6665 12.0001V17.3334C18.6665 18.0951 18.0949 18.6667 17.3332 18.6667H11.9998C11.6462 18.6668 11.3071 18.8073 11.0571 19.0573C10.807 19.3074 10.6665 19.6465 10.6665 20.0001V25.3334C10.6665 27.5264 12.4736 29.3334 14.6665 29.3334H25.3332C27.5261 29.3334 29.3332 27.5264 29.3332 25.3334V14.6667C29.3332 12.4738 27.5261 10.6667 25.3332 10.6667H19.9998ZM21.3332 13.3334H25.3332C26.0949 13.3334 26.6665 13.905 26.6665 14.6667V25.3334C26.6665 26.0951 26.0949 26.6667 25.3332 26.6667H14.6665C13.9048 26.6667 13.3332 26.0951 13.3332 25.3334V21.3334H17.3332C19.5261 21.3334 21.3332 19.5264 21.3332 17.3334V13.3334Z" fill="white"/>
                                        <path d="M6.66354 2.66724C4.47061 2.66724 2.66748 4.47036 2.66748 6.6633V17.3326C2.66748 19.5255 4.47061 21.3287 6.66354 21.3287H17.3328C19.5258 21.3287 21.3289 19.5255 21.3289 17.3326V6.6633C21.3289 4.47036 19.5258 2.66724 17.3328 2.66724H6.66354ZM6.66354 5.33456H17.3328C18.0946 5.33456 18.6714 5.90157 18.6714 6.6633V17.3326C18.6714 18.0943 18.0946 18.6712 17.3328 18.6712H6.66354C5.90181 18.6712 5.3348 18.0943 5.3348 17.3326V6.6633C5.3348 5.90157 5.90181 5.33456 6.66354 5.33456Z" fill="white"/>
                                        </svg>
                                        <h3>Digital Marketing</h3>

                                    </div>
                                    <ul>
                                        <li>Landing pages</li>
                                        <li>Multi-page sites</li>
                                        <li>Marketplaces</li>
                                        <li>Mobile and Web Apps</li>
                                        <li>Product design</li>
                                        <li>Layout of sites on no code platforms</li>
                                        <li>Creation email templates</li>
                                    </ul>

                                </div>
                            </div>
                            <div className="col-6">
                                <div className="glowing-card">
                                    <div className="d-flex gap-4 align-items-center">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.9998 10.6667C19.6462 10.6668 19.3071 10.8073 19.0571 11.0573C18.807 11.3074 18.6665 11.6465 18.6665 12.0001V17.3334C18.6665 18.0951 18.0949 18.6667 17.3332 18.6667H11.9998C11.6462 18.6668 11.3071 18.8073 11.0571 19.0573C10.807 19.3074 10.6665 19.6465 10.6665 20.0001V25.3334C10.6665 27.5264 12.4736 29.3334 14.6665 29.3334H25.3332C27.5261 29.3334 29.3332 27.5264 29.3332 25.3334V14.6667C29.3332 12.4738 27.5261 10.6667 25.3332 10.6667H19.9998ZM21.3332 13.3334H25.3332C26.0949 13.3334 26.6665 13.905 26.6665 14.6667V25.3334C26.6665 26.0951 26.0949 26.6667 25.3332 26.6667H14.6665C13.9048 26.6667 13.3332 26.0951 13.3332 25.3334V21.3334H17.3332C19.5261 21.3334 21.3332 19.5264 21.3332 17.3334V13.3334Z" fill="white"/>
                                        <path d="M6.66354 2.66724C4.47061 2.66724 2.66748 4.47036 2.66748 6.6633V17.3326C2.66748 19.5255 4.47061 21.3287 6.66354 21.3287H17.3328C19.5258 21.3287 21.3289 19.5255 21.3289 17.3326V6.6633C21.3289 4.47036 19.5258 2.66724 17.3328 2.66724H6.66354ZM6.66354 5.33456H17.3328C18.0946 5.33456 18.6714 5.90157 18.6714 6.6633V17.3326C18.6714 18.0943 18.0946 18.6712 17.3328 18.6712H6.66354C5.90181 18.6712 5.3348 18.0943 5.3348 17.3326V6.6633C5.3348 5.90157 5.90181 5.33456 6.66354 5.33456Z" fill="white"/>
                                        </svg>
                                        <h3>Motion Graphics</h3>

                                    </div>
                                    <ul>
                                        <li>Landing pages</li>
                                        <li>Multi-page sites</li>
                                        <li>Marketplaces</li>
                                        <li>Mobile and Web Apps</li>
                                        <li>Product design</li>
                                        <li>Layout of sites on no code platforms</li>
                                        <li>Creation email templates</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="preview-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center title">
                                <h3>UI/UX <span>Designs</span></h3>
                                <p>Sed velit ultricies convallis placerat semper lectus viverra. Id enim vel faucibus dolor et id.<br/> Varius orci condimentum sed felis, scelerisque eget massa consectetur vitae. </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="preview-wrapper">
                                <img src={require('../../../assets/previewimg1.png')} alt="" />
                                <h4>Caixaforum — video and podcast content platform</h4>
                                <p>PRODUCT DESIGN / UX/UI / VISUAL DESIGN</p>
                            </div>
                            <div className="preview-wrapper">
                                <img src={require('../../../assets/previewimg2.png')} alt="" />
                                <h4>Caixaforum — video and podcast content platform</h4>
                                <p>PRODUCT DESIGN / UX/UI / VISUAL DESIGN</p>
                            </div>
                            <div className="preview-wrapper">
                                <img src={require('../../../assets/previewimg3.png')} alt="" />
                                <h4>Caixaforum — video and podcast content platform</h4>
                                <p>PRODUCT DESIGN / UX/UI / VISUAL DESIGN</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
    )
}

export default Servicepage;